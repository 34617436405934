import { formatPrice } from 'src/utilities/helpers';

export class Product {
  constructor (product) {
    this.id = product.id;
    this.shortDescription = product.shortDescription;
    this.subTitle = product.subTitle;
    this.longDescription = product.longDescription;
    this.properties = product.properties;
    this.targetUrl = product.targetUrl;
    this.imageUrl = product.imageUrl;
    this.images = product.images;
    this.brand = product.brand;
    this.variantKey = product.variantKey;
    this.units = product.units;
    this.saleUnit = product.saleUnit;
    this.discountGroup = product.discountGroup;
    this.prices = [];
    this.units = this.setUnits(product.units);
    this.stock = null;
    this.variantKey = product.variantKey;
    this.productVariants = [];
    this.customStrings = product.customStrings;
    this.customDecimals = product.customDecimals;
    this.customBooleans = product.customBooleans;
    this.customDateTimes = product.customDateTimes;
    this.includedInCustomerLists = [];
  }

  setStock (stock) {
    this.stock = stock;
  }

  setUnits (units) {
    if (units) {
      return units.sort((a, b) => (a.quantity > b.quantity) ? 1 : -1)
    } else return null;
  }

  setProductVariants (productVariants) {
    this.productVariants = productVariants;
  }

  setCustomerLists (customerList) {
    this.includedInCustomerLists = customerList;
  }

  setPrices (prices) {
    if (prices !== undefined) {
      prices.forEach(priceObj => {
        priceObj.rawPrice = priceObj.price;
        priceObj.rawBasePrice = priceObj.basePrice;
        priceObj.price = formatPrice(priceObj.price);
        priceObj.basePrice = formatPrice(priceObj.basePrice);
        priceObj.quantity = priceObj.quantity === 0 ? 1 : priceObj.quantity;
      });
    }
    this.prices = prices;
  }
}
