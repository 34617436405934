import axios from 'axios';
import { getUrlParams, createUrlParams } from 'src/utilities/url';
import { Product } from 'src/models/product.model';

const state = {
  loadingProducts: true,
  noProductsFound: false,
  productCodes: [],
  products: [],
  showMobileFilters: false,
  totalProducts: 0,
  currentPage: 1,
  totalPages: 1,
  layoutType: '',
  blockSize: 4,
  rangeFrom: 0,
  pageSize: 16,
  queryString: '',
  aggregations: false,
  pricingQueryString: '',
  activeFilters: {},
  filters: [],
  pageSizes: [],
  sort: '',
  sortingType: 0,
  sortingOptions: [],
  customerListId: '',
  searchConfig: {
    UserId: null,
    Category: null,
    StringProperties: null,
    NumericProperties: null,
    StringAggregations: null,
    NumericAggregations: null,
    ExtraQueries: []
  }
};

const getters = {
  pageTitle (state) {
    return state.pageTitle ? state.pageTitle : state.queryString;
  },
  layoutType (state) {
    return state.layoutType;
  },
  language (state, getters, rootState, rootGetters) {
    return rootGetters.language;
  },
  blockSize (state) {
    return state.blockSize;
  },
  totalProducts (state) {
    return state.totalProducts;
  },
  totalPages () {
    return Math.ceil(state.totalProducts / state.pageSize);
  },
  currentPage (state) {
    return state.currentPage;
  },
  loadingProducts (state) {
    return state.loadingProducts;
  },
  pageRange (state, getters) {
    let to;
    let pageFillCount = state.totalProducts % state.pageSize;
    if (pageFillCount && getters.totalPages === state.currentPage) {
      to = state.rangeFrom + pageFillCount;
    } else {
      to = state.pageSize + state.rangeFrom;
    }
    return {
      from: state.rangeFrom + 1,
      to: to
    };
  },
  showMobileFilters (state, getters, rootState, rootGetters) {
    if (rootGetters.screenWidth < 991) {
      return state.showMobileFilters;
    } else {
      return true;
    }
  },
  noProductsFound (state) {
    return state.noProductsFound;
  },
  searchConfig (state) {
    return {
      UserId: null,
      Category: state.searchConfig.Category,
      StringProperties: state.activeFilters,
      NumericProperties: null,
      StringAggregations: state.searchConfig.StringAggregations,
      NumericAggregations: null,
      ExtraQueries: state.searchConfig.ExtraQueries,
      ExcludeLabels: state.searchConfig.ExcludeLabels,
      ExcludeCategories: state.searchConfig.ExcludeCategories,
      IncludeLabels: state.searchConfig.IncludeLabels,
      ShouldIncludeLabels: state.searchConfig.ShouldIncludeLabels
    }
  },
  filters (state) {
    return state.filters;
  },
  elasticProductsEndpoint (state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.elasticProductsEndpoint;
    const from = state.rangeFrom;
    const language = rootGetters.language;
    const size = state.pageSize;
    const aggregations = state.aggregations;
    const pricingQueryString = state.pricingQueryString;
    const customerListId = state.customerListId;
    const query = !state.queryString ? '' : state.queryString;
    const customerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    const client = rootGetters.clientCode;
    const priceListCode = rootGetters.customerPriceList;
    const sort = state.sort;
    const sortingType = state.sortingType;
    var sortingstring = ''


    // BESPOKE KIE-62
    // Sorting is always done based on product code, never default or category
    
    //if (sort !== null) sortingstring = '&sort=' + sort + '|' + sortingType;
    sortingstring = '&sort=id|0';
    // END BESPOKE KIE-62

    return `${endpoint}?lang=${language}${sortingstring}&from=${from}&size=${size}&aggr=${aggregations}&query=${query}&customerId=${customerId}&customerPricelist=${priceListCode}&listId=${customerListId}&client=${client}&pricingQuerystring=${pricingQueryString}&groupProducts=true`;
  },
  productInformationEndpoint (state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.productInformationEndpoint;
    const client = rootGetters.clientCode;
    const language = rootGetters.language;

    return `${endpoint}/${client}?language=${language}`;
  },
  productCodes (state) {
    return state.productCodes;
  }
};

const mutations = {
  setLoadingStatus (state, payload) {
    state.loadingProducts = payload;
  },
  setProducts (state, products) {
    state.products = products;
  },
  noProductsFound (state, productsFound) {
    state.noProductsFound = productsFound;
  },
  setProductCodes (state, productCodes) {
    state.productCodes = productCodes;
  },
  setFilters (state, aggregations) {
    const filters = [...aggregations];
    // Update new filters array with active status based on cached active filters array
    filters.forEach(filter => {
      // check for undefined as aggregations may give back new keys
      if (state.activeFilters[filter.id] !== undefined) {
        filter.value.forEach(filterValue => {
          if (state.activeFilters[filter.id].Values.indexOf(filterValue.key) > -1) {
            filterValue.active = true;
          } else {
            filterValue.active = false;
          }
        });
      }
    });

    // BESPOKE KIE
    // Sort filter values numerically or alphabetically
    filters.forEach(filter => {
      // Determine alphabetically or numberically based on first filter value
      var filterValue = parseInt(filter.value[0].key);
      if (!Number.isNaN(filterValue)) {
        filter.value = filter.value.sort((a, b) => parseInt(a.key) - parseInt(b.key));
      } else {
        filter.value = filter.value.sort((a, b) => a.key.localeCompare(b.key));
      }
    });
    const sortedFilters = filters.sort((a, b) => a.label.localeCompare(b.label));
    state.filters = [...sortedFilters];
    // END BESPOKE KIE
  },
  setProductStock (state, productWithStock) {
    let index = state.products.findIndex(product => product.id === productWithStock.id);
    state.products[index] = productWithStock;
  },
  setProductPrice (state, productWithPrice) {
    let index = state.products.findIndex(product => product.id === productWithPrice.id);
    state.products[index] = productWithPrice;
  },
  setCustomerLists (state, productWithList) {
    let index = state.products.findIndex(product => product.id === productWithList.id);
    state.products[index] = productWithList;
  },
  setTotalProducts (state, totalProducts) {
    state.totalProducts = totalProducts;
  },
  setTotalPages (state) {
    state.totalPages = Math.ceil(state.totalProducts / state.pageSize);
  },
  initConfig (state, config) {
    state.rangeFrom = config.From;
    state.blockSize = config.BlockSize;
    state.aggregations = config.Aggregations;
    state.pricingQueryString = config.PricingQueryString;
    state.customerListId = config.CustomerListId;
    state.queryString = !config.QueryString ? '' : config.QueryString;
    state.pageSize = config.Size;
    state.pageTitle = config.CategoryName;
    state.pageSizes = config.PageSizes;
    state.layoutType = config.LayoutType;
    state.sortingOptions = config.SortingOptions;
    if (state.sortingOptions[0].Field !== '') {
      state.sort = state.sortingOptions[0].Field;
      state.sortingType = state.sortingOptions[0].Type;
    }
  },
  initSearchConfig (state, searchConfig) {
    state.searchConfig = { ...searchConfig };
  },
  updateConfigWithParameters (state, config) {
    state.rangeFrom = config.from;
    state.queryString = config.queryString;
    state.pageSize = config.pageSize;
    state.currentPage = config.currentPage;
  },
  changePage (state, pageNumber) {
    state.rangeFrom = (pageNumber - 1) * state.pageSize;
    state.currentPage = pageNumber;
    state.products = [];
  },
  changeLayout (state, layoutType) {
    state.layoutType = layoutType;
  },
  changePageSize (state, pageSize) {
    state.pageSize = pageSize;
  },
  changePageSorting (state, pageSorting) {
    state.sortingType = pageSorting.Type;
    state.sort = pageSorting.Field;
  },
  updateActiveFilters (state, { filterActive, filterId, filterValue, language }) {
    let activeFilters = { ...state.activeFilters };
    if (typeof activeFilters[filterId] === 'undefined') {
      activeFilters[filterId] = [];
    }
    if (filterActive) {
      activeFilters[filterId] = { Values: [filterValue], Language: language, PartialSearch: false };
    } else {
      delete activeFilters[filterId];
    }
    state.activeFilters = activeFilters;
  },
  setProductVariants (state, { products, productId }) {
    let index = state.products.findIndex(product => product.id === productId);
    state.products[index].setProductVariants(products);
  },
  toggleMobileFilters (state) {
    state.showMobileFilters = !state.showMobileFilters;
  },
  updateUrl (state) {
    let filters = {};
    if (Object.entries(state.activeFilters).length !== 0) {
      Object.keys(state.activeFilters).forEach(filter => {
        filters[filter] = state.activeFilters[filter];
      });
    }
    const urlParams = createUrlParams({
      searchtext: state.queryString,
      from: state.rangeFrom,
      pageSize: state.pageSize,
      filters: filters
    });
    history.pushState({}, '', urlParams);
  }
};

const actions = {
  initElastic ({ commit, dispatch }) {
    dispatch('readoutUrl');
    dispatch('getProducts');
  },
  readoutUrl ({ commit, rootGetters }) {
    if (window.location.search.length) {
      const params = getUrlParams(location.href);
      const from = parseInt(params.from) ? parseInt(params.from) : 0;
      const pageSize = parseInt(params.pageSize) ? parseInt(params.pageSize) : state.pageSize;
      const queryString = params.searchtext ? params.searchtext : state.queryString;
      const currentPage = from === 0 ? 1 : from / pageSize + 1;
      const filters = params.filters;

      commit('updateConfigWithParameters', { from: from, pageSize: pageSize, queryString: queryString, currentPage: currentPage });

      if (typeof filters !== 'undefined') {
        Object.keys(filters).forEach(filter => {
          commit('updateActiveFilters', {
            filterActive: true,
            filterId: filter,
            filterValue: filters[filter][0],
            language: rootGetters.language
          });
        });
      }
    }
  },
  getProducts ({ commit, getters, dispatch, rootGetters }) {
    commit('setLoadingStatus', true);
    axios.post(getters.elasticProductsEndpoint, getters.searchConfig)
      .then(res => {
        if (res.data.products) {
          commit('setProductCodes', res.data.products);
          commit('noProductsFound', false);
          commit('setFilters', res.data.stringAggregations);
          commit('setTotalProducts', res.data.totalItems);
          commit('setLoadingStatus', false);
          commit('setTotalPages');
          commit('updateUrl');
          dispatch('getProductInformation');
        } else {
          commit('noProductsFound', true);
        }
      });
  },
  getProductInformation ({ commit, getters, dispatch, rootGetters }) {
    let mainProductCodes = getters.productCodes.map(code => code.key);
    axios.post(getters.productInformationEndpoint, mainProductCodes)
      .then(res => {
        const products = res.data.map(product => new Product(product));

        if (rootGetters.showStock) {
          dispatch('getProductStock', products);
        } else {
          products.forEach(product => { product.setStock({ stockTotal: 0 }); })
        }

        if (rootGetters.showPrices) {
          dispatch('getProductPrices', products);
        }

        if (rootGetters.showFavorites) {
          dispatch('getCustomerLists', products);
        }
        commit('setProducts', products);
      });
  },
  getProductPrices ({ commit, getters, dispatch, rootGetters }, products) {
    let priceRequestWrapper = {};
    priceRequestWrapper.CustomerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    priceRequestWrapper.Pricelist = rootGetters.customerPriceList;
    priceRequestWrapper.Products = products.map(prod => {
      let ret = {};
      ret.ProductId = prod.id;
      ret.ProductGroup = prod.discountGroup;

      ret.ProductUnit = '';
      if (prod.units !== undefined && prod.units !== null) {
        ret.ProductUnit = prod.units.length > 0 ? prod.units[0].code : '';
      }
      return ret;
    });

    let endpoint = rootGetters.productPriceEndpoint + 'prices/' + rootGetters.clientCode

    axios.post(endpoint, priceRequestWrapper)
      .then(res => {
        res.data.forEach(price => {
          let product = products[products.findIndex(x => x.id === price.productId)];
          product.setPrices(Object.keys(price.volumes).map(key => price.volumes[key]));
          commit('setProductPrice', product);
        });
      });
  },
  getProductVariants ({ commit, dispatch, rootGetters, getters }, { variantCode, productId }) {
    const productVariantCodes = getters.productCodes.filter(code => code.key === variantCode)[0].value;
    axios.post(getters.productInformationEndpoint, productVariantCodes)
      .then(res => {
        const products = res.data.map(product => new Product(product));
        if (rootGetters.showStock) {
          products.forEach((product, index) => {
            axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
              .then(res => {
                product.setStock(res.data.d);
              });
          });
        } else {
          products.forEach(product => { product.setStock({ stockTotal: 120 }); })
        }
        commit('setProductVariants', { products: products, productId: productId });
        dispatch('getProductVariantPrices', products);
      });
  },
  getProductVariantPrices ({ commit, getters, dispatch, rootGetters }, products) {
    let priceRequestWrapper = {};
    priceRequestWrapper.CustomerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    priceRequestWrapper.Pricelist = rootGetters.customerPriceList;
    priceRequestWrapper.Products = products.map(prod => {
      let ret = {};
      ret.ProductId = prod.id;
      ret.ProductGroup = prod.discountGroup;

      ret.ProductUnit = '';
      if (prod.units !== undefined && prod.units !== null) {
        ret.ProductUnit = prod.units.length > 0 ? prod.units[0].code : '';
      }
      return ret;
    });

    let endpoint = rootGetters.productPriceEndpoint + 'prices/' + rootGetters.clientCode

    axios.post(endpoint, priceRequestWrapper)
      .then(res => {
        res.data.forEach(price => {
          let product = products[products.findIndex(x => x.id === price.productId)];
          product.setPrices(Object.keys(price.volumes).map(key => price.volumes[key]));
        });
      });
  },
  getCustomerLists ({ commit, getters, rootGetters }, products) {
    const endpoint = `${rootGetters.getProductsInListsEndpoint}?client=${rootGetters.clientCode}&debtorId=${rootGetters.customerId}`;
    axios.post(endpoint, getters.productCodes.map(code => code.key))
      .then(res => {
        Object.keys(res.data).forEach(prodCode => {
          let filteredProduct = products[products.findIndex(product => product.id === prodCode)];
          if (filteredProduct !== undefined) {
            filteredProduct.setCustomerLists(res.data[prodCode]);
            commit('setCustomerLists', filteredProduct);
          }
        });
      });
  },
  updateFilters ({ commit, dispatch, rootGetters }, changedFilter) {
    commit('updateActiveFilters', { ...changedFilter, language: rootGetters.language });
    commit('changePage', 1);
    dispatch('getProducts');
  },
  getProductStock ({ commit, rootGetters }, products) {
    products.forEach(product => {
      axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
        .then(res => {
          product.setStock(res.data.d);
          commit('setProductStock', product);
        });
    });
  },
  changePage ({ commit, dispatch }, pageNumber) {
    commit('changePage', pageNumber);
    dispatch('getProducts');
  },
  changePageSize ({ commit, dispatch }, pageSize) {
    commit('changePageSize', pageSize);
    commit('changePage', 1);
    dispatch('getProducts');
  },
  changePageSorting ({ commit, dispatch }, pageSorting) {
    commit('changePageSorting', pageSorting);
    commit('changePage', 1);
    dispatch('getProducts');
  },
  changeLayout ({ commit }, layoutType) {
    commit('changeLayout', layoutType);
  },
  toggleMobileFilters ({ commit }) {
    commit('toggleMobileFilters');
  }
};

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
