export function createUrlParams (paramsObj) {
  let urlParams = '?';
  Object.keys(paramsObj).forEach((key, i) => {
    if (i) urlParams += '&';
    if (typeof paramsObj[key] === 'object' && Object.entries(paramsObj[key]).length === 0) {
      urlParams += `${key}=`;
    } else if (typeof paramsObj[key] === 'object' && Object.entries(paramsObj[key]).length !== 0) {
      let arrParams = '';
      let paramKeys = Object.keys(paramsObj[key]);
      paramKeys.forEach((param, i) => {
        let values = paramsObj[key][param].Values.filter(val => val !== '');

        if (values.length) {
          arrParams += `${param}[${values}]`;
          if (i < paramKeys.length - 1) {
            arrParams += '|';
          }
        } else {
          delete paramsObj[key][param];
        }
      });
      urlParams += `${key}[]=${arrParams}`;
    } else {
      urlParams += `${key}=${paramsObj[key]}`;
    }
  });
  return encodeURI(urlParams);
}

export function getUrlParams (url) {
  const obj = {};
  const decodedUrl = decodeURI(url);
  const queryString = decodedUrl ? decodedUrl.split('?')[1] : window.location.search.slice(1);

  if (queryString) {
    let queryParts = queryString.split('&');
    queryParts.forEach(part => {
      let parts = part.split('=');
      let queryKey = parts[0].replace('[', '').replace(']', '');
      let queryValue = parts[1];

      // If value is an array &filters[]
      if (queryValue.includes('[')) {
        // Add new param entry
        obj[queryKey] = {};
        let queryValueArr = queryValue.split('|');
        // If query array has more than one array
        if (queryValueArr.length) {
          let arrObj = {};
          queryValueArr.forEach(val => {
            if (val !== '') {
              let parts = val.split('[');
              let key = parts[0];
              let values = parts[1].replace(']', '');
              arrObj[key] = values.split(',');
            }
          });
          obj[queryKey] = arrObj;
        }
      } else {
        obj[queryKey] = queryValue;
      }
    });
  }
  return obj;
}
