const state = {
};

const getters = {
  productStockEndpoint (state) {
    return state.productStockUrl;
  },
  orderProductEndpoint (state) {
    return state.orderProductUrl;
  },
  productPriceEndpoint (state) {
    return state.productPriceUrl;
  },
  getProductsInListsEndpoint (state) {
    return state.customerList + '/GetProductLists';
  },
  addProductToListEndpoint (state) {
    return state.customerList + '/AddProductToCustomerList';
  },
  removeProductFromListEndpoint (state) {
    return state.customerList + '/RemoveProductFromCustomerList';
  },
  elasticProductsEndpoint (state) {
    return state.elasticProducts;
  },
  productInformationEndpoint (state) {
    return state.productInformation;
  },
  productVariantsEndpoint (state) {
    return state.elasticProducts + '/GetByProductVariantKey'
  },
  customerListEndpoint (state) {
    return state.customerList;
  },
  bespokeEndpoints (state) {
    return state.bespokeEndpoints;
  }
};

const mutations = {
  setGlobalEndpoints (state, globalEndpointsObj) {
    Object.assign(state, globalEndpointsObj);
  }
};

const actions = {
  setGlobalEndpoints ({ commit }, globalEndpointsObj) {
    commit('setGlobalEndpoints', globalEndpointsObj);
  }
};

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};
