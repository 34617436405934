<template>
  <div class="utlz-order-normal">
  </div>
</template>

<script>
export default {
  props: {
    useOrderMode: { type: Boolean, required: false, default: true },
    initialValue: { type: Number, required: false, default: 1 }
  },
  data () {
    return {
      quantityValue: this.initialValue
    }
  },
  methods: {
    quantityChanged () {
      console.log('changed');
    },
    decrement () {
      this.quantityValue--;
      this.quantityChanged();
    },
    increment () {
      this.quantityValue++;
      this.quantityChanged();
    },
    orderProduct (payload) {
      this.$emit('orderNormal', { quantity: parseInt(this.quantityValue) });
      this.quantityValue = this.initialValue;
    }
  }
};
</script>

<style>
</style>
